<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import appConfig from "@/app.config";
import blogSvc from "@/services/blog";
import { toast } from "vue3-toastify";

/**
 * Customers component
 */
export default {
  page: {
    title: "Blog List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Blog List",
      role:
        localStorage.getItem("userInfo") !== null
          ? JSON.parse(localStorage.getItem("userInfo")).role
          : "user",
      items: [
        {
          text: "Blog",
          href: "#",
        },
        {
          text: "Blog List",
          active: true,
        },
      ],
      columns: [
        {
          label: "",
          field: "lineNumber",
          sortable: false,
        },
        {
          field: "uuid",
          hidden: true,
        },
        {
          field: "statusText",
          hidden: true,
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Status",
          field: "statusCode",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Status",
            filterDropdownItems: [
              { value: "created", text: "Draft" },
              { value: "pending_verify", text: "Pending Verify" },
              { value: "publish", text: "Publish" },
              { value: "editing", text: "Editing" },
              { value: "rejected", text: "Rejected" },
            ],
            trigger: "enter",
          },
        },
        {
          label: "Highlight",
          field: "isHighlight",
        },
        {
          label: "Interested",
          field: "isInterested",
        },
        {
          label: "Created By",
          field: "createdBy",
        },
        {
          label: "Created Date",
          field: "createdAt",
          type: "Date",
        },
        {
          label: "Last Updated",
          field: "updatedAt",
          type: "Date",
        },
        {
          label: "Status",
          field: "isActive",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Status",
            filterDropdownItems: [
              { value: "true", text: "On" },
              { value: "false", text: "Off" },
            ],
            trigger: "enter",
          },
          sortable: false,
        },
        {
          label: "Action",
          field: "button",
          html: true,
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      rows: [],
      totalRows: 0,
      table: {
        page: 1,
        perPage: 10,
        status: "",
        isActive: "",
        sort: {
          by: "",
          type: "",
        },
        search: "",
      },
      typingTimer: null,
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      blogSvc.getAllV2().then((resp) => {
        if (resp.data.data.blog === null) {
          this.rows = [];
          return;
        }

        this.rows = resp.data.data;
      });
    },
    updateBlogHighlight(uuid) {
      blogSvc
        .updateBlogHighlight(uuid)
        .then((resp) => {
          if (resp.status === 200) {
            toast.success("Update Highlight Successfully", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          } else {
            toast.error("Update Highlight Failed", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: localStorage.getItem("toastCountdown"),
          });
        })
        .finally(() => {
          this.get();
        });
    },
    updateBlogInterested(uuid) {
      blogSvc
        .updateBlogInterested(uuid)
        .then((resp) => {
          if (resp.status === 200) {
            toast.success("Update Interested Successfully", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          } else {
            toast.error("Update Interested Failed", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: localStorage.getItem("toastCountdown"),
          });
        })
        .finally(() => {
          this.get();
        });
    },
    deleteBlog(uuid) {
      blogSvc
        .deleteBlog(uuid)
        .then((resp) => {
          if (resp.status === 200) {
            toast.success("Change Status Successfully", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          } else {
            toast.error("Change Status Failed", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: localStorage.getItem("toastCountdown"),
          });
        })
        .finally(() => {
          this.get();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12">
                <div class="text-sm-end">
                  <b-button
                    class="btn btn-success btn-rounded mb-2 me-2"
                    router-link
                    :to="`manage`"
                  >
                    <i class="mdi mdi-plus me-1"></i> New Blog
                  </b-button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <vue-good-table
                :search-options="{
                  enabled: true,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: 10,
                  mode: 'records',
                }"
                :line-numbers="true"
                styleClass="tableOne vgt-table striped"
                :columns="columns"
                :rows="rows"
              >
                <template #table-row="props">
                  <span v-if="props.column.field == 'button'">
                    <b-button
                      v-if="props.formattedRow.statusCode === 'pending_verify'"
                      size="sm"
                      :variant="role !== 'user' ? 'warning' : 'primary'"
                      router-link
                      :to="`manage?uuid=${props.formattedRow.uuid}`"
                    >
                      <i
                        :class="`mdi mdi-${
                          role !== 'user' ? 'file-check-outline' : 'eye'
                        } font-size-16 align-middle`"
                      ></i>
                      {{ role !== "user" ? "Verify" : "View" }}
                    </b-button>
                    <b-button
                      v-else-if="
                        ['publish', 'unpublish', 'editing'].includes(
                          props.formattedRow.statusCode
                        )
                      "
                      size="sm"
                      variant="primary"
                      router-link
                      :to="`manage?uuid=${props.formattedRow.uuid}`"
                    >
                      <i class="mdi mdi-eye font-size-16 align-middle"></i>
                      View
                    </b-button>
                    <b-button
                      v-else
                      size="sm"
                      variant="success"
                      router-link
                      :to="`manage?uuid=${props.formattedRow.uuid}`"
                    >
                      <i class="mdi mdi-pencil font-size-16 align-middle"></i>
                      Edit
                    </b-button>
                  </span>
                  <span v-else-if="props.column.field == 'statusCode'">
                    <b-badge
                      v-if="
                        [
                          'created',
                          'pending_verify',
                          'editing',
                          'draft',
                          'updated',
                        ].includes(props.row.statusCode)
                      "
                      variant="warning"
                      >{{ props.formattedRow["statusText"] }}</b-badge
                    >
                    <b-badge
                      v-else-if="
                        ['rejected', 'unpublish'].includes(props.row.statusCode)
                      "
                      variant="danger"
                      >{{ props.formattedRow["statusText"] }}</b-badge
                    >
                    <b-badge
                      v-else-if="
                        ['approved', 'publish', 'publish_draft'].includes(
                          props.row.statusCode
                        )
                      "
                      variant="success"
                      >{{ props.formattedRow["statusText"] }}</b-badge
                    >
                    <b-badge v-else>-</b-badge>
                  </span>
                  <span v-else-if="props.column.field == 'isHighlight'">
                    <b-form-checkbox
                      v-model="props.formattedRow.isHighlight"
                      name="check-button"
                      switch
                      @change="updateBlogHighlight(props.formattedRow.uuid)"
                    >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-success"
                        v-if="props.formattedRow.isHighlight"
                        >Yes</span
                      >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-warning"
                        v-else
                        >No</span
                      >
                    </b-form-checkbox>
                  </span>
                  <span v-else-if="props.column.field == 'isInterested'">
                    <b-form-checkbox
                      v-model="props.formattedRow.isInterested"
                      name="check-button"
                      switch
                      @change="updateBlogInterested(props.formattedRow.uuid)"
                    >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-success"
                        v-if="props.formattedRow.isInterested"
                        >Yes</span
                      >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-warning"
                        v-else
                        >No</span
                      >
                    </b-form-checkbox>
                  </span>
                  <span v-else-if="props.column.field == 'isActive'">
                    <b-form-checkbox
                      v-model="props.formattedRow.isActive"
                      name="check-button"
                      switch
                      @change="deleteBlog(props.formattedRow.uuid)"
                    >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-success"
                        v-if="props.formattedRow.isActive"
                        >On</span
                      >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-warning"
                        v-else
                        >Off</span
                      >
                    </b-form-checkbox>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style>
th.line-numbers {
  border-bottom: none !important;
}
</style>
